import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import Button from '../components/Button';
import FloatingButton from '../components/FloatingButton';
import { PageElements, P, SmallP } from '../components/PageElements';
import {
  setLocale as setCookieLocale,
  setCountry as setCookieCountry,
} from '../utils/cookie';
import { CheckButton } from '../components/CheckButton';
import { countries } from '../components/i18n';
import { PeikkoContext } from '../contexts/PeikkoContext';

const CountryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Country = () => {
  const [country, setCountry] = useState<string>(null);
  const [locale, setLocale] = useState<string>(null);
  const { i18n } = useContext(PeikkoContext);
  const router = useRouter();

  const updateLocale = (newLocale: string): void => {
    i18n.updateLocale(newLocale);
  };

  const selectCountry = (newCountry: string): void => {
    const selectedCountry = countries.find((c) => newCountry === c.shortname);
    setCountry(selectedCountry.shortname);
    setCookieCountry(selectedCountry.shortname);
    const defaultLocale = selectedCountry.languages[0].locale;
    if (selectedCountry.languages.length === 1) {
      setLocale(defaultLocale);
      setCookieLocale(defaultLocale);
    } else {
      setLocale(null);
    }
    updateLocale(defaultLocale);
  };

  const next = (): void => {
    if (locale) {
      router.push('/');
    } else {
      router.push('/locale');
    }
  };

  return (
    <>
      <PageElements>
        <div className="!mb-28 w-full">
          <P role="heading" aria-level={1}>
            {i18n.t('country.header')}
          </P>
          <SmallP style={{ marginBottom: '3em' }}>
            {i18n.t('country.subheader')}
          </SmallP>
          <CountryContainer>
            {countries
              .filter((c) => !c.hidden)
              .map((c) => (
                <CheckButton
                  key={c.shortname}
                  onClick={() => selectCountry(c.shortname)}
                  selected={country ? country === c.shortname : false}
                  buttonText={c.name}
                />
              ))}
          </CountryContainer>
        </div>
      </PageElements>
      <FloatingButton>
        <Button onClick={next} disabled={!country}>
          {i18n.t('country.continue')}
        </Button>
      </FloatingButton>
    </>
  );
};

export default Country;
